import {FormAnswerBase, FormQuestionBase, SelectOption} from '@shared/models/form.model';
import {JSONAnswer} from '@shared/models/to-json';
import * as geojson from 'geojson';
import {divIcon, LatLngLiteral, MapOptions} from 'leaflet';

export interface FormMapQuestion extends FormQuestionBase {
  mapOptions: Required<Pick<MapOptions, 'center' | 'zoom'>>;
  tags: MapTag[];
}
export enum MapTagType {
  Marker,
  Circle,
  Plot
}

export enum ControlAvailability {
  Off,
  On,
  Required
}

export interface MapMarkerTag {
  id: number;
  color: string;
  label: string;
  type: MapTagType;
  minCount: number | null;
  maxCount: number | null;
  datePicker: ControlAvailability;
  comment: ControlAvailability;
  select: ControlAvailability;
  selectOptions?: SelectOption[];
  selectMultiple?: boolean;
}

export interface MapCircleTag extends MapMarkerTag {
  minRadius: number;
  maxRadius: number;
}
export type MapTag = MapMarkerTag | MapCircleTag;

export interface MapTagAnswer {
  latLng: LatLngLiteral;
  tagId: MapTag['id'];
  comment?: string;
  radius?: number;
  date?: Date;
  selection?: number | number[];
}
export interface MapPlotAnswer extends MapTagAnswer {
  plotId: string;
  plotPolygon: LatLngLiteral[];
}
export type MapAnswer = MapTagAnswer | MapPlotAnswer;
export interface FormMapAnswer extends FormAnswerBase<MapAnswer[]> {}

export const tagIcon = (color: string, type: MapTagType) =>
  divIcon({
    className: 'my-custom-pin',
    iconAnchor: [0, 24],
    // labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: `<span style="
      background-color: ${color};
      width: 2em;
      height: 2em;
      display: block;
      left: -1em;
      top: -1em;
      position: relative;
      border-radius: ${type == MapTagType.Circle ? '2em' : type == MapTagType.Marker ? '2em 2em 0' : '0'};
      ${type != MapTagType.Plot ? 'transform: rotate(45deg);' : ''}
      border: 1px solid #FFFFFF" />`
  });

export function mapToJSON(
  {question, tags}: FormMapQuestion,
  {answer}: FormMapAnswer
): JSONAnswer<geojson.FeatureCollection<geojson.GeometryObject>> {
  const tagsMap = tags.reduce((dict: Record<number, MapTag>, tag) => ({...dict, [tag.id]: tag}), {});

  return {
    question,
    answer: {
      type: 'FeatureCollection',
      features: answer.map(({tagId, ...a}) => toGeoJSON(tagsMap[tagId], a))
    }
  };
}

type GeoJSONProperties = Pick<MapTagAnswer, 'radius' | 'comment' | 'date'> &
  Pick<MapMarkerTag, 'label'> & {
    selection?: SelectOption['label'] | SelectOption['label'][];
  };

function toGeoJSON(
  {label, selectOptions, type}: MapTag,
  answer: Omit<MapAnswer, 'tagId'>
): geojson.Feature<geojson.Point | geojson.Polygon, GeoJSONProperties> {
  const {latLng, radius, comment, date, selection} = answer;
  return {
    type: 'Feature',
    geometry:
      type === MapTagType.Plot
        ? {
            type: 'Polygon',
            coordinates: [(answer as MapPlotAnswer).plotPolygon.map(({lat, lng}) => [lng, lat])]
          }
        : {
            type: 'Point',
            coordinates: [latLng.lng, latLng.lat]
          },
    properties: {
      radius,
      date,
      label,
      ...(comment && {comment}),
      ...(type === MapTagType.Plot && {plotId: (answer as MapPlotAnswer).plotId}),
      ...(selection &&
        selectOptions && {
          selection: Array.isArray(selection)
            ? selection.map(id => selectOptions.find(opt => opt.id == id)?.label || id.toString())
            : selectOptions.find(opt => opt.id == selection)?.label || selection.toString()
        })
    }
  };
}
