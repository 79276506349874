<ng-container *ngIf="screenWidth > 768">
  <div class="header">
    <div *ngIf="loggedIn$ | async">
      <a mat-button (click)="routeToManage()">{{ 'admin-panel' | transloco }}</a>
      <a mat-button (click)="routeToUserPanel()">{{ 'profile' | transloco }}</a>
    </div>
    <div>
      <ng-container *ngIf="!(loggedIn$ | async); else logOut">
        <a mat-button (click)="register()">{{ 'register.text' | transloco }}</a>
        <a mat-button (click)="signIn()">{{ 'signIn' | transloco }}</a>
      </ng-container>
      <ng-template #logOut>
        <a mat-button (click)="signOut()">{{ 'signOut' | transloco }}</a>
      </ng-template>
    </div>
    <ng-content select="[languageSelect]"></ng-content>
  </div>
</ng-container>

<ng-container *ngIf="screenWidth < 768">
  <button mat-icon-button (click)="isMenuOpen = !isMenuOpen">
    <mat-icon>menu</mat-icon>
  </button>
  <div [ngClass]="{'menu-open': isMenuOpen}" class="mobile-header">
    <ng-container *ngIf="loggedIn$ | async">
      <a mat-button (click)="routeToManage(); isMenuOpen = !isMenuOpen">{{ 'admin-panel' | transloco }}</a>
      <a mat-button (click)="routeToUserPanel();isMenuOpen = !isMenuOpen">{{ 'profile' | transloco }}</a>
    </ng-container>
    <ng-container *ngIf="!(loggedIn$ | async); else logOut">
      <a mat-button (click)="register();isMenuOpen = !isMenuOpen">{{ 'register.text' | transloco }}</a>
      <a mat-button (click)="signIn();isMenuOpen = !isMenuOpen">{{ 'signIn' | transloco }}</a>
    </ng-container>
    <ng-template #logOut>
      <a mat-button (click)="signOut();isMenuOpen = !isMenuOpen">{{ 'signOut' | transloco }}</a>
    </ng-template>
  </div>
</ng-container>
