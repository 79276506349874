import {FormArray} from '@ngneat/reactive-forms';
import {FormAnswer, FormQuestion} from '@shared/forms';
import firebase from 'firebase/app';

import {FormQuestionType} from './form-question-type.enum';
import {FormStatus} from './form-status.enum';
import {nestedReplace, NonFunctionProperties, Replace} from './replace';

export import Timestamp = firebase.firestore.Timestamp;

export interface SelectOption {
  id: number;
  label: string;
}

export interface FormQuestionBase {
  id: number;
  type: FormQuestionType;
  question: string;
  imageFile?: File | null;
  imageUrl?: string;
}

export interface FormAnswerBase<T> {
  questionId: number;
  answer: T;
}

export type Form = {
  id?: string;
  name: string;
  description: string;
  start: Date;
  end: Date;
  status: FormStatus;
  questions: FormQuestion[];
  submissionCount: number;
  ownerId: string;
};

export type FormSubmission = {
  answers: FormAnswer[];
  submissionDate: Date;
};
export interface Counter {
  count: number | firebase.firestore.FieldValue;
}

export type Timestamped<T extends Record<string, unknown>> = Replace<T, Date, Timestamp>;

export const timestampedToDate = <T extends Record<string, unknown>>(source: Timestamped<T>): T =>
  nestedReplace(
    source,
    val => val instanceof Timestamp,
    (val: Timestamp) => val.toDate()
  ) as T;

export const dateToTimestamped = <T extends Record<string, unknown>>(source: T): Timestamped<T> =>
  nestedReplace(
    source,
    val => val instanceof Date,
    (val: Date) => Timestamp.fromDate(val)
  );

type Serialized<T extends Record<string, unknown>> = Replace<T, Timestamp, NonFunctionProperties<Timestamp>>;

export type SerializedForm = Serialized<Omit<Timestamped<Form>, 'id'>> & {
  submissions?: Serialized<Timestamped<FormSubmission>>[];
};

export const serializedToTimestamped = <T extends Timestamped<Record<string, unknown>>>(source: Serialized<T>): T =>
  nestedReplace(
    source,
    val => (typeof val == 'object' && val && 'seconds' in val && 'nanoseconds' in val) || false,
    (val: NonFunctionProperties<Timestamp>) => new Timestamp(val.seconds, val.nanoseconds)
  ) as T;

export const nextId = <T extends {id: number}>(array: FormArray<T>) => Math.max(...array.value.map(item => item.id), 0) + 1;

export const increment = firebase.firestore.FieldValue.increment(1);
export const decrement = firebase.firestore.FieldValue.increment(-1);
