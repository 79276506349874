import {HttpClient} from '@angular/common/http';
import {APP_INITIALIZER, Injectable} from '@angular/core';
import {FIREBASE_OPTIONS} from '@angular/fire';
import {environment} from '@env/environment';
import config from 'assets/config/production.json';
import {tap} from 'rxjs/operators';

@Injectable()
export class ConfigService {
  private config: typeof config | undefined;

  constructor(private http: HttpClient) {}

  load() {
    return this.http.get<typeof config>(`assets/config/${environment.config}.json`).pipe(tap(config => (this.config = config)));
  }

  get(key: keyof typeof config) {
    if (!this.config) {
      throw new Error('config not loaded. Make sure to load config in APP_INITIALIZER');
    }
    return this.config[key];
  }
}

export function init() {
  return [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ConfigService) => () => config.load().toPromise(),
      deps: [ConfigService],
      multi: true
    },
    {
      provide: FIREBASE_OPTIONS,
      useFactory: (config: ConfigService) => config.get('firebase'),
      deps: [ConfigService]
    }
  ];
}

const ConfigModule = {
  init: init
};

export {ConfigModule};
