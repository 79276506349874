import {FormControl, FormGroup} from '@ngneat/reactive-forms';
import {FormQuestionBase} from '@shared/models/form.model';
import {Observable} from 'rxjs';

export abstract class FormEditBaseComponent<TQuestion extends FormQuestionBase> {
  abstract questionForm: FormGroup<TQuestion>;

  set id(id: number) {
    this.questionForm.getControl('id').setValue(id);
  }
  abstract set value(value: TQuestion);

  formDisabled$!: Observable<boolean>;

  get getImageUrl() {
    const imageUrl = this.questionForm.get('imageUrl')?.value;
    return imageUrl ?? '';
  }

  handleImageUploaded(image: File) {
    this.questionForm.get('imageFile')?.patchValue(image);
  }

  get questionControl() {
    return this.questionForm.getControl('question') as FormControl<string>;
  }
}
