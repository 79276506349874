import {animate, style, transition, trigger} from '@angular/animations';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {Validators} from '@angular/forms';
import {MatChipInputEvent, MatChipList} from '@angular/material/chips';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {AbstractControl} from '@ngneat/reactive-forms/lib/types';
import {TranslocoService} from '@ngneat/transloco';
import {FormMapQuestion} from '@shared/components';
import {FormEditBaseComponent} from '@shared/components/base/form-edit-base';
import {nextId, SelectOption} from '@shared/models/form.model';
import {FormQuestionType} from '@shared/models/form-question-type.enum';
import {LatLng, latLng, Map} from 'leaflet';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {ControlAvailability, MapCircleTag, MapMarkerTag, MapTag, MapTagType, tagIcon} from '../form-map.model';
import {MapLayersService} from '../map-layers.service';

@Component({
  selector: 'app-form-map-edit',
  templateUrl: './form-map-edit.component.html',
  styleUrls: ['./form-map-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slide', [
      transition(':enter', [style({'max-height': 0}), animate(225)]),
      transition(':leave', [animate(225, style({'max-height': 0}))])
    ])
  ],
  providers: [MapLayersService]
})
export class FormMapEditComponent extends FormEditBaseComponent<FormMapQuestion> implements OnDestroy {
  private map: Map | undefined;

  constructor(private mapLayers: MapLayersService, private fb: FormBuilder, private transloco: TranslocoService) {
    super();
  }
  ngOnDestroy(): void {
    this.formDestroy$.next();
  }
  formDestroy$ = new Subject<void>();

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  controlAvailability = ControlAvailability;
  colorPickerOpen = false;

  defaultMapOptions: FormMapQuestion['mapOptions'] = {
    zoom: 7,
    center: {
      ...latLng(52, 19)
    }
  };
  layersControl = this.mapLayers.layersControl;

  tagTypes = [
    {id: MapTagType.Marker, label: this.transloco.translate('tag.marker')},
    {id: MapTagType.Circle, label: this.transloco.translate('tag.circle')},
    {id: MapTagType.Plot, label: this.transloco.translate('tag.plot')}
  ];
  tagType = MapTagType;

  questionForm = new FormGroup<FormMapQuestion>({
    id: new FormControl<number>(),
    type: new FormControl(FormQuestionType.Map),
    question: new FormControl('', Validators.required),
    tags: new FormArray<MapMarkerTag>([], [Validators.required, Validators.minLength(1)]),
    mapOptions: new FormControl(this.defaultMapOptions),
    imageUrl: new FormControl(),
    imageFile: new FormControl()
  });

  set value(value: FormMapQuestion) {
    if (value.mapOptions) {
      this.defaultMapOptions = value.mapOptions;
    }
    if (value.tags) {
      const {tags, ...other} = value;
      this.questionForm.patchValue(other);
      this.questionForm.setControl(
        'tags',
        this.fb.array(
          tags.map(tag =>
            this.fb.group<MapTag>({
              ...tag,
              selectOptions: this.fb.array(tag.selectOptions?.map(x => this.fb.group<SelectOption>(x)) || []),
              selectMultiple: tag.selectMultiple || false,
              minRadius: (tag as MapCircleTag).minRadius || 10,
              maxRadius: (tag as MapCircleTag).maxRadius || 1000
            })
          ),
          [Validators.required, Validators.minLength(1)]
        )
      );
      this.tagsControl.controls.forEach(this.setValidators);
    } else {
      this.questionForm.setValue(value);
    }
  }
  get mapOptionsControl() {
    return this.questionForm.getControl('mapOptions');
  }
  get tagsControl() {
    return this.questionForm.getControl('tags') as FormArray<MapTag>;
  }

  onMapReady(map: Map) {
    this.map = map;
    this.layersControl.baseLayers['Open Street Map'].addTo(map);
  }
  onCenterChange(center: LatLng) {
    const mapOptions = this.mapOptionsControl.value;
    this.mapOptionsControl.setValue({...mapOptions, center: {...center}});
  }
  onZoomChange(zoom: number) {
    const mapOptions = this.mapOptionsControl.value;
    this.mapOptionsControl.setValue({...mapOptions, zoom});
  }
  resetMap() {
    this.map?.setView(this.defaultMapOptions.center, this.defaultMapOptions.zoom);
  }

  addTag() {
    const tag = this.fb.group<MapTag>({
      id: nextId(this.tagsControl),
      color: `hsla(${Math.random() * 360}, 100%, 50%, 1)`,
      type: MapTagType.Marker,
      label: '',
      minCount: 0,
      maxCount: 10,
      minRadius: 10,
      maxRadius: 1000,
      datePicker: ControlAvailability.Off,
      comment: ControlAvailability.On,
      select: ControlAvailability.Off,
      selectOptions: this.fb.array<SelectOption>([]),
      selectMultiple: false
    });
    this.setValidators(tag);
    this.tagsControl.push(tag);
  }

  private setValidators(tag_: AbstractControl<MapTag>) {
    const tag = tag_ as FormGroup<MapCircleTag>;
    const selectOptions = tag.getControl('selectOptions') as FormArray<SelectOption>;

    tag.getControl('label').setValidators(Validators.required);
    selectOptions.setValidators([Validators.required, Validators.minLength(2)]);

    tag.getControl('minRadius').disabledWhile(tag.getControl('type').value$.pipe(map(type => type != MapTagType.Circle)));
    tag.getControl('maxRadius').disabledWhile(tag.getControl('type').value$.pipe(map(type => type != MapTagType.Circle)));
    tag.getControl('selectMultiple')?.disabledWhile(tag.getControl('select').value$.pipe(map(select => select == ControlAvailability.Off)));
    selectOptions.disabledWhile(tag.getControl('select').value$.pipe(map(select => select == ControlAvailability.Off)));
  }
  icon(tag: AbstractControl<MapTag>) {
    const {color, type} = (tag as FormGroup<MapTag>).getRawValue();
    return tagIcon(color, type).createIcon().innerHTML;
  }

  onChipListInit(tag: AbstractControl<MapTag>, chipList: MatChipList) {
    this.tagSelectOptions(tag)
      .status$.pipe(takeUntil(this.formDestroy$))
      .subscribe(status => (chipList.errorState = status === 'INVALID'));
  }

  tagSelectOptions = (tag: AbstractControl<MapTag>) => (tag as FormGroup<MapTag>).getControl('selectOptions') as FormArray<SelectOption>;
  addTagSelectOption(tag: AbstractControl<MapTag>, event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const tagOptions = this.tagSelectOptions(tag);
      tagOptions.push(
        this.fb.group<SelectOption>({
          id: nextId(tagOptions),
          label: event.value
        })
      );
    }
    if (input) {
      input.value = '';
    }
  }
  removeTagSelectOption = (tag: AbstractControl<MapTag>, option: SelectOption) => this.tagSelectOptions(tag).remove(option);

  onColorSelect(tag: AbstractControl<MapTag>, color: string) {
    (tag as FormGroup<MapTag>).patchValue({color});
  }

  removeTag(tag: MapTag) {
    this.tagsControl.remove(tag);
  }
}
