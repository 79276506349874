import {OverlayContainer} from '@angular/cdk/overlay';
import {Component, HostListener, OnInit} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {ConfigService} from '@core/config/config.service';
import {availableLangs} from '@core/i18n/transloco-root.module';
import {DateAdapter} from '@matheo/datepicker';
import {TranslocoService} from '@ngneat/transloco';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'geo-forms';
  themeClass = '';
  screenWidth!: number;
  clickLogoSubject: Subject<null> = new Subject<null>();

  emitLogoClickToHeader() {
    this.clickLogoSubject.next(null);
  }

  constructor(
    private auth: AngularFireAuth,
    public transloco: TranslocoService,
    private dateAdapter: DateAdapter<Date>,
    private config: ConfigService,
    private overlayContainer: OverlayContainer
  ) {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.themeClass = this.config.get('theme').toString();
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('-theme'));
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }
    overlayContainerClasses.add(this.themeClass);
  }

  loggedIn$ = this.auth.user.pipe(map(x => x && !x.isAnonymous));

  availableLangs = availableLangs;

  onLangChange(lang: string) {
    this.transloco.setActiveLang(lang);
    this.dateAdapter.setLocale(lang);
  }
}
