import {FormAnswerBase, FormQuestionBase} from '@shared/models/form.model';
import {JSONAnswer} from '@shared/models/to-json';

export interface FormScaleQuestion extends FormQuestionBase {
  min: number;
  max: number;
  minLabel?: string;
  maxLabel?: string;
}

export interface FormScaleAnswer extends FormAnswerBase<number> {}

export function scaleToJSON({question}: FormScaleQuestion, {answer}: FormScaleAnswer): JSONAnswer<number> {
  return {
    question,
    answer
  };
}
