import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-scale-stat',
  templateUrl: './form-scale-stat.component.html',
  styleUrls: ['./form-scale-stat.component.scss']
})
export class FormScaleStatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
