import {FormAnswerBase, FormQuestionBase, SelectOption} from '@shared/models/form.model';
import {JSONAnswer} from '@shared/models/to-json';

export interface FormSingleQuestion extends FormQuestionBase {
  options: SelectOption[];
  allowOther: boolean;
}

export interface FormSingleAnswer extends FormAnswerBase<number> {
  other?: string;
}

export function singleToJSON({question, options}: FormSingleQuestion, {answer, other}: FormSingleAnswer): JSONAnswer<string> {
  return {
    question,
    answer: other || options.find(opt => opt.id == answer)?.label || answer.toString()
  };
}
