import {Component} from '@angular/core';

@Component({
  selector: 'app-filled',
  templateUrl: './filled.component.html',
  styleUrls: ['./filled.component.scss']
})
export class FilledComponent {
  constructor() {}
}
