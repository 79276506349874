import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalizationInterceptor implements HttpInterceptor {
  constructor(private transloco: TranslocoService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    req = req.clone({
      setHeaders: {
        'Accept-Language': this.transloco.getActiveLang()
      }
    });
    return next.handle(req);
  }
}
