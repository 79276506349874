<div class="main-container">
  <div class="header-container" fxLayout="row" fxLayoutAlign="center center">
    <span>{{ 'home.welcome' | transloco }}</span>
  </div>
  <div class="information-container">{{ 'home.form-open' | transloco }}</div>
  <mat-form-field fxLayout="row" class="form-field">
    <input matInput [(ngModel)]="formId" />
  </mat-form-field>
  <button mat-button class="open-button" (click)="openForm()">{{ 'home.open' | transloco }}</button>
</div>
