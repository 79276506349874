<mat-card>
  <mat-card-title>
    <mat-form-field class="question">
      <mat-label>{{ 'question' | transloco }}</mat-label>
      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="4" [formControl]="questionControl"> </textarea>
      <mat-error *ngIf="questionControl.errors?.required && questionControl.touched">{{
        'errors.required-question' | transloco
      }}</mat-error>
    </mat-form-field>
  </mat-card-title>
  <app-photo [isEditMode]="true" [imageUrl]="getImageUrl" (imageSelected)="handleImageUploaded($event)"></app-photo>
</mat-card>
