import {A11yModule} from '@angular/cdk/a11y';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule} from '@angular/material/tooltip';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {MatDatepickerModule, MatNativeDateModule} from '@matheo/datepicker';
import {SubscribeModule} from '@ngneat/subscribe';
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';
import {ColorPickerModule} from 'ngx-color-picker';

import {
  FormMapEditComponent,
  FormMapStatComponent,
  FormMapViewComponent,
  FormMultiEditComponent,
  FormMultiStatComponent,
  FormMultiViewComponent,
  FormOpenEditComponent,
  FormOpenStatComponent,
  FormOpenViewComponent,
  FormScaleEditComponent,
  FormScaleStatComponent,
  FormScaleViewComponent,
  FormSingleEditComponent,
  FormSingleStatComponent,
  FormSingleViewComponent
} from './components';
import {ErrorRequiredComponent} from './components/base/error-required/error-required.component';
import {ClosedComponent} from './components/closed/closed.component';
import {FilledComponent} from './components/filled/filled.component';
import {FooterComponent} from './components/footer/footer.component';
import {MenuItemsComponent} from './components/menu-items/menu-items.component';
import {PhotoComponent} from './components/photo/photo.component';
import {ThankYouComponent} from './components/thank-you/thank-you.component';
import {NgInitDirective} from './directives/ng-init.directive';
import {StopClickPropagationDirective} from './directives/stop-click-propagation.directive';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';

function getCurrentLang(transloco: TranslocoService) {
  return transloco.getActiveLang();
}

@NgModule({
  declarations: [
    MenuItemsComponent,
    FooterComponent,
    FormOpenViewComponent,
    FormOpenEditComponent,
    FormOpenStatComponent,
    FormSingleEditComponent,
    FormSingleStatComponent,
    FormSingleViewComponent,
    FormMultiViewComponent,
    FormMultiEditComponent,
    FormMultiStatComponent,
    FormScaleStatComponent,
    FormScaleEditComponent,
    FormScaleViewComponent,
    FormMapViewComponent,
    FormMapEditComponent,
    FormMapStatComponent,
    ErrorRequiredComponent,
    PhotoComponent,
    SafeHtmlPipe,
    ThankYouComponent,
    StopClickPropagationDirective,
    NgInitDirective,
    ClosedComponent,
    FilledComponent,
    FooterComponent,
    PhotoComponent
  ],
  imports: [
    A11yModule,
    MatProgressSpinnerModule,
    CommonModule,
    AngularFirestoreModule,
    LeafletModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    TranslocoModule,
    MatCheckboxModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSliderModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    ColorPickerModule,
    TranslocoModule,
    PortalModule,
    OverlayModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SubscribeModule
  ],
  exports: [
    MenuItemsComponent,
    FooterComponent,
    CommonModule,
    A11yModule,
    AngularFireAuthModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    LeafletModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatListModule,
    MatSortModule,
    MatPaginatorModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    TranslocoModule,
    MatTabsModule,
    MatTableModule,
    MatRadioModule,
    MatBadgeModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTooltipModule,
    PortalModule,
    ClipboardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SubscribeModule
  ],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {position: 'above'} as MatTooltipDefaultOptions},
    {provide: MAT_DATE_LOCALE, useFactory: getCurrentLang, deps: [TranslocoService]}
  ]
})
export class SharedModule {}
