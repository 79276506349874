<div class="site-footer" *transloco="let t">
  <div class="container">
    <div class="site-footer__bottom">
      <div class="site-footer__copyright">
        Powered by <a href="https://angular.io/" target="_blank">Angular</a> — Design by
        <a href="https://www.tmssoft.pl/" target="_blank">Tailored Mobile Solutions</a>
      </div>
    </div>
  </div>
</div>
