<mat-card class="wrapper">
    <mat-card-content>
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" fxLayout="column">
        <h2>{{ 'register.text' | transloco }}</h2>
        <mat-form-field class="full-width-input">
          <input matInput placeholder="{{ 'email' | transloco }}" formControlName="email" />
          <mat-error *ngIf="registerForm.get('email').errors?.required">{{ 'register.fieldRequired' | transloco }}</mat-error>
          <mat-error *ngIf="registerForm.get('email').hasError('email')">{{ 'register.inappropriateEmail' | transloco }}</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput type="password" placeholder="{{ 'register.password' | transloco }}" formControlName="password" />
          <mat-error *ngIf="registerForm.get('password').errors?.required">{{ 'register.fieldRequired' | transloco }}</mat-error>
          <mat-error *ngIf="registerForm.get('password').errors?.minlength">{{ 'register.minLengthErr' | transloco }}</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput  placeholder="{{ 'register.firstName' | transloco }}" formControlName="firstName" />
          <mat-error *ngIf="registerForm.get('firstName').errors?.required">{{ 'register.fieldRequired' | transloco }}</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput  placeholder="{{ 'register.lastName' | transloco }}" formControlName="lastName" />
          <mat-error *ngIf="registerForm.get('lastName').errors?.required">{{ 'register.fieldRequired' | transloco }}</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input type="number" matInput  placeholder="{{ 'register.phoneNumber' | transloco }}" formControlName="phoneNumber" />
          <mat-error *ngIf="registerForm.get('phoneNumber').errors?.required">{{ 'register.fieldRequired' | transloco }}</mat-error>
        </mat-form-field>
        <mat-error *ngIf="errorMessage"> {{ errorMessage }} </mat-error>
        <button [disabled]="!this.registerForm.valid" mat-raised-button color="primary">{{ 'register.text' | transloco }}</button>
      </form>
    </mat-card-content>
  </mat-card>
  