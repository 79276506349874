import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {FormControl, FormGroup} from '@ngneat/reactive-forms';
import {FormScaleQuestion} from '@shared/components';
import {FormEditBaseComponent} from '@shared/components/base/form-edit-base';
import {FormQuestionType} from '@shared/models/form-question-type.enum';

@Component({
  selector: 'app-form-scale-edit',
  templateUrl: './form-scale-edit.component.html',
  styleUrls: ['./form-scale-edit.component.scss']
})
export class FormScaleEditComponent extends FormEditBaseComponent<FormScaleQuestion> implements OnInit {
  minValues: number[] = [];
  maxValues: number[] = [];

  questionForm = new FormGroup<FormScaleQuestion>({
    id: new FormControl<number>(),
    type: new FormControl(FormQuestionType.Scale),
    question: new FormControl('', Validators.required),
    min: new FormControl<number>(1, Validators.required),
    max: new FormControl<number>(5, Validators.required),
    minLabel: new FormControl(''),
    maxLabel: new FormControl(''),
    imageUrl: new FormControl(),
    imageFile: new FormControl()
  });

  get minLabel() {
    return this.questionForm.getControl('minLabel') as FormControl<string>;
  }

  get maxLabel() {
    return this.questionForm.getControl('maxLabel') as FormControl<string>;
  }

  set value(value: FormScaleQuestion) {
    this.questionForm.setValue({...value, imageFile: null});
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initValuesArrays();
  }

  initValuesArrays() {
    this.minValues.push(0);
    this.minValues.push(1);
    for (let x = 2; x <= 10; x++) {
      this.maxValues[x - 2] = x;
    }
  }
}
