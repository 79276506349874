import {FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {FormAnswerBase, FormQuestionBase} from '@shared/models/form.model';

export abstract class FormViewBaseComponent<TQuestion extends FormQuestionBase, TAnswer extends FormAnswerBase<unknown>> {
  set data(question: TQuestion | null) {
    if (!question) {
      return;
    }
    this._data = question;
    this.answerForm.getControl('questionId').patchValue(question.id);
  }
  get data() {
    return this._data;
  }

  get getImageUrl() {
    return this.data?.imageUrl ?? '';
  }

  private _data: TQuestion | null = null;

  abstract answerForm: FormGroup<TAnswer>;

  get answerControl() {
    return this.answerForm.getControl('answer') as AnswerControl<TAnswer>;
  }
}
type AnswerControl<T> = T extends FormAnswerBase<infer R>
  ? R extends Array<infer S>
    ? // eslint-disable-next-line @typescript-eslint/ban-types
      S extends object
      ? FormArray<S>
      : FormControl<R>
    : FormControl<R>
  : never;
