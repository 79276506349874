<mat-card>
  <mat-card-title>
    <mat-form-field class="question">
      <mat-label>{{ 'question' | transloco }}</mat-label>
      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="4" [formControl]="questionControl"> </textarea>
      <mat-error *ngIf="questionControl.errors?.required && questionControl.touched">{{
        'errors.required-question' | transloco
      }}</mat-error>
    </mat-form-field>
  </mat-card-title>
  <app-photo [isEditMode]="true" [imageUrl]="getImageUrl" (imageSelected)="handleImageUploaded($event)"></app-photo>
  <div fxLayout="row" fxLayoutAlign="space-evenly space">
    <mat-form-field class="scale-value-container">
      <mat-label>{{ 'minValue' | transloco }}</mat-label>
      <mat-select [formControl]="questionForm.controls.min">
        <mat-option *ngFor="let value of minValues" [value]="value">{{ value }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div fxLayoutAlign="center center">-</div>
    <mat-form-field class="scale-value-container">
      <mat-label>{{ 'maxValue' | transloco }}</mat-label>
      <mat-select [formControl]="questionForm.controls.max">
        <mat-option *ngFor="let value of maxValues" [value]="value">{{ value }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-error
    *ngIf="
      (questionForm.getControl('min').touched && questionForm.get('min').errors?.required) ||
      (questionForm.get('max').errors?.required && questionForm.getControl('max').touched)
    "
    >{{ 'errors.required-values' | transloco }}</mat-error
  >
  <div fxLayout="row" fxFlexAlign="center" class="labels-container">
    <mat-form-field>
      <mat-label>{{ questionForm.get('min').value }}</mat-label>
      <input matInput [formControl]="minLabel" />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxFlexAlign="center">
    <mat-form-field>
      <mat-label>{{ questionForm.get('max').value }}</mat-label>
      <input matInput [formControl]="maxLabel" />
    </mat-form-field>
  </div>
</mat-card>
