export type Replace<S extends Record<string, unknown>, F, T> = {
  [K in keyof S]: S[K] extends F
    ? T
    : S[K] extends Array<infer U>
    ? U extends Record<string, unknown>
      ? Array<Replace<U, F, T>>
      : Array<U>
    : S[K] extends Record<string, unknown>
    ? Replace<S[K], F, T>
    : S[K];
};

export function nestedReplace<S extends Record<string, unknown>, F, T>(
  source: S,
  match: (s: unknown) => boolean,
  replace: (s: F) => T
): Replace<S, F, T> {
  const result: Record<string, unknown> = {};
  for (const key in source) {
    const value = source[key];
    if (match(value)) {
      result[key] = replace(value as F);
    } else if (value && Array.isArray(value)) {
      result[key] = value.map(val => (typeof val === 'object' ? nestedReplace(val, match, replace) : val));
    } else if (value && typeof value === 'object') {
      result[key] = nestedReplace(value as never, match, replace);
    } else {
      result[key] = value;
    }
  }
  return result as Replace<S, F, T>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type NonFunctionPropertyNames<T> = {[K in keyof T]: T[K] extends Function ? never : K}[keyof T];
export type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;
