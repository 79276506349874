import {HttpClient} from '@angular/common/http';
import {Injectable, NgModule} from '@angular/core';
import {environment} from '@env/environment';
import {
  getBrowserLang,
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule
} from '@ngneat/transloco';

export const availableLangs = [
  {
    id: 'en',
    label: 'English'
  },
  {
    id: 'pl',
    label: 'Polski'
  }
];

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs,
        defaultLang: availableLangs.find(({id}) => id === getBrowserLang())?.id ?? availableLangs[0].id,
        reRenderOnLangChange: true,
        prodMode: environment.production
      })
    },
    {provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader}
  ]
})
export class TranslocoRootModule {}
