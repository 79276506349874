<div *ngIf="!isEditMode; else uploadSection">
  <img *ngIf="uploadedImageUrl" class="upload-photo" [src]="uploadedImageUrl" alt="question image" />
</div>
<ng-template #uploadSection>
  <label class="custom-file-input">
    Wybierz zdjęcie do pytania
    <input type="file" (change)="onImageSelected($event)" />
  </label>
  <img class="upload-photo mt-1" *ngIf="uploadedImageUrl" [src]="uploadedImageUrl" alt="question image" />
</ng-template>
