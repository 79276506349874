import {Component} from '@angular/core';
import {Validators} from '@angular/forms';
import {FormControl, FormGroup} from '@ngneat/reactive-forms';
import {FormOpenQuestion} from '@shared/components';
import {FormEditBaseComponent} from '@shared/components/base/form-edit-base';
import {FormQuestionType} from '@shared/models/form-question-type.enum';

@Component({
  selector: 'app-form-open-edit',
  templateUrl: './form-open-edit.component.html',
  styleUrls: ['./form-open-edit.component.scss']
})
export class FormOpenEditComponent extends FormEditBaseComponent<FormOpenQuestion> {
  questionForm = new FormGroup<FormOpenQuestion>({
    id: new FormControl<number>(),
    type: new FormControl<FormQuestionType>(FormQuestionType.Open),
    question: new FormControl('', Validators.required),
    imageUrl: new FormControl(),
    imageFile: new FormControl()
  });

  set value(value: FormOpenQuestion) {
    // this.questionForm.setValue(value);
    this.questionForm.setValue({...value, imageFile: null});
  }

  constructor() {
    super();
  }
}
