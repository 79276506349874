import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {FormControl, FormGroup} from '@ngneat/reactive-forms';
import {FormViewBaseComponent} from '@shared/components/base/form-view-base';
import {map} from 'rxjs/operators';

import {FormSingleAnswer, FormSingleQuestion} from '../form-single.model';

@Component({
  selector: 'app-form-single-view',
  templateUrl: './form-single-view.component.html',
  styleUrls: ['./form-single-view.component.scss']
})
export class FormSingleViewComponent extends FormViewBaseComponent<FormSingleQuestion, FormSingleAnswer> implements OnInit {
  constructor() {
    super();
  }
  ngOnInit(): void {
    this.otherControl.disabledWhile(this.answerControl.value$.pipe(map(v => v != this.otherId)));
  }
  otherId = -1;

  answerForm = new FormGroup<FormSingleAnswer>({
    answer: new FormControl<number>(undefined, Validators.required),
    other: new FormControl<string>(undefined, Validators.required),
    questionId: new FormControl<number>(undefined, Validators.required)
  });

  get otherControl() {
    return this.answerForm.getControl('other') as FormControl<string>;
  }
}
