<mat-card>
  <mat-card-title>
    {{ data?.question || 'Failed to load..' }}
  </mat-card-title>
  <app-photo [isEditMode]="false" [imageUrl]="getImageUrl"></app-photo>
  <mat-card-content *ngIf="data">
    <mat-form-field fxLayout="column" fxLayoutAlign="space-between" class="answer-form">
      <mat-label>{{ 'answer' | transloco }}</mat-label>
      <textarea matInput [formControl]="answerControl"></textarea>
    </mat-form-field>
    <app-error-required [answerControl]="answerControl"></app-error-required>
  </mat-card-content>
</mat-card>
