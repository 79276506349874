import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent {
  @Input() imageUrl!: string;
  @Input() isEditMode = false;
  @Output() imageSelected = new EventEmitter<File>();

  selectedImage: File | null = null;
  uploadedImageUrl: string | null = null;

  ngOnChanges() {
    if (this.imageUrl) {
      this.uploadedImageUrl = this.imageUrl;
    }
  }

  onImageSelected(event: any) {
    this.selectedImage = event.target.files[0];
    if (this.selectedImage) {
      this.imageSelected.emit(this.selectedImage);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.uploadedImageUrl = e.target.result;
      };
      reader.readAsDataURL(this.selectedImage);
    }
  }
}
