import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-single-stat',
  templateUrl: './form-single-stat.component.html',
  styleUrls: ['./form-single-stat.component.scss']
})
export class FormSingleStatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
