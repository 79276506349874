import {FormAnswerBase, FormQuestionBase} from '@shared/models/form.model';
import {JSONAnswer} from '@shared/models/to-json';

export interface FormOpenQuestion extends FormQuestionBase {}

export interface FormOpenAnswer extends FormAnswerBase<string> {}

export function openToJSON({question}: FormOpenQuestion, {answer}: FormOpenAnswer): JSONAnswer<string> {
  return {
    question,
    answer
  };
}
