import 'proj4leaflet';

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import L, {LatLng, LatLngLiteral, tileLayer} from 'leaflet';
import {map} from 'rxjs/operators';

const gugikUrl = 'https://integracja.gugik.gov.pl/cgi-bin/KrajowaIntegracjaEwidencjiGruntow';
const uldkUrl = 'https://uldk.gugik.gov.pl';

@Injectable()
export class MapLayersService {
  crs2180 = new L.Proj.CRS(
    'EPSG:2180',
    '+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
    {
      resolutions: [8192, 4096, 2048]
    }
  );
  gugikLayer = tileLayer.wms(gugikUrl, {
    layers: 'dzialki,numery_dzialek,budynki',
    transparent: true,
    format: 'image/png',
    crs: this.crs2180
  });
  osmLayer = tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  });
  ortoFotoLayer = tileLayer(
    'https://mapy.geoportal.gov.pl/wss/service/PZGIK/ORTO/WMTS/StandardResolution?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=ORTOFOTOMAPA&STYLE=default&FORMAT=image/jpeg' +
      '&TILEMATRIXSET=EPSG:3857&TILEMATRIX=EPSG:3857:{z}&TILEROW={y}&TILECOL={x}'
  );

  layersControl = {
    baseLayers: {
      'Open Street Map': this.osmLayer,
      'Orto Foto': this.ortoFotoLayer
    },
    overlays: {
      GUGiK: this.gugikLayer
    }
  };

  constructor(private http: HttpClient) {}

  getPlot(latlng: LatLng | LatLngLiteral) {
    const {x, y} = this.crs2180.project(latlng);
    const params = new HttpParams({
      fromObject: {
        xy: [x, y].join(','),
        srid: '4326',
        request: 'GetParcelByXY',
        result: 'teryt,geom_wkt'
      }
    });
    return this.http.get(uldkUrl, {params, observe: 'body', responseType: 'text'}).pipe(
      map(res => {
        const found = res.split('\n');
        if (found.length > 1 && found[0] == '0') {
          const [id, wkt] = found[1].split('|');

          return {id, latlngs: this.parseWKT(wkt)};
        } else {
          return null;
        }
      })
    );
  }
  private parseWKT(wkt: string) {
    const wktRegEx = /(\w+)\(+([\d., ]+)\)+/;
    const matched = wkt.match(wktRegEx);
    if (!matched) throw new Error('failed to parse WKT');

    const polyType = matched[1];
    if (polyType === 'POLYGON') {
      return matched[2]
        .split(',')
        .map(coords => coords.split(' ').map(c => Number(c)))
        .map(([lng, lat]) => ({lat, lng} as LatLngLiteral));
    } else {
      throw new Error(`parsing WKT type ${polyType} not supported`);
    }
  }
}
