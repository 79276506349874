import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  formId = '';
  constructor(private router: Router) {}

  openForm() {
    if (this.formId && this.formId != '') {
      this.router.navigate(['/form', this.formId]);
    }
  }
}
