<div [class]="themeClass">
  <mat-toolbar class="mat-elevation-z6 toolbar">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <div class="title-container" fxLayout="row" fxLayoutAlign="center">
        <img src="../assets/images/tms.png" alt="TMS" />
        <h2 class="app-title" fxLayout="row" fxLayoutAlign="center center" (click)="emitLogoClickToHeader()" routerLink="/">GeoAnkieta</h2>
      </div>
      <div class="toolbar-items" fxLayout="row" fxLayoutAlign="end center">
        <mat-select languageSelect class="menu-select" [value]="transloco.getActiveLang()" (valueChange)="onLangChange($event)">
          <mat-option *ngFor="let lang of availableLangs" [value]="lang.id">
            {{ lang.label }}
          </mat-option>
        </mat-select>
        <app-menu-items [screenWidth]="screenWidth" [clickLogoEvent]="clickLogoSubject.asObservable()"></app-menu-items>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="wrapper">
    <router-outlet></router-outlet>
  </div>
  <footer>
    <app-footer></app-footer>
  </footer>
</div>
