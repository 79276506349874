import {Component, OnInit} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {UserRegister} from '@core/models/user-register.model';
import {FormControl, FormGroup, ValidatorFn} from '@ngneat/reactive-forms';
import {TranslocoService} from '@ngneat/transloco';
import {Validators} from '@angular/forms';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  registerForm = new FormGroup<UserRegister>({
    email: new FormControl<string>(undefined, [Validators.required, Validators.email]),
    password: new FormControl<string>(undefined, [Validators.required, Validators.minLength(6)]),
    phoneNumber: new FormControl<number>(undefined, Validators.required),
    firstName: new FormControl<string>(undefined, Validators.required),
    lastName: new FormControl<string>(undefined, Validators.required)
  });
  errorMessage: string | null = null;
  constructor(private afs: AngularFirestore, private auth: AngularFireAuth, private router: Router, private transloco: TranslocoService) {}

  onSubmit() {
    if (this.registerForm.valid) {
      const {email, password, phoneNumber, firstName, lastName} = this.registerForm.value;
      this.auth
        .createUserWithEmailAndPassword(email, password)
        .then(data => {
          if (data.user) {
            this.afs.collection('users').doc(data.user.uid).set({
              firstName,
              lastName,
              phoneNumber,
              email
            });
          }
          this.router.navigate(['/']);
        })
        .catch(error => {
          /**
           * t(auth/user-not-found, auth/wrong-password, auth/user-disabled, auth/invalid-email)
           */
          this.errorMessage = this.transloco.translate(error.code) ?? error.message;
        });
    }
  }
}
