<mat-card>
  <mat-card-title>
    <mat-form-field class="question">
      <mat-label>{{ 'question' | transloco }}</mat-label>
      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="4" [formControl]="questionControl"> </textarea>
      <mat-error *ngIf="questionControl.errors?.required && questionControl.touched">{{
        'errors.required-question' | transloco
      }}</mat-error>
    </mat-form-field>
  </mat-card-title>
  <app-photo [isEditMode]="true" [imageUrl]="getImageUrl" (imageSelected)="handleImageUploaded($event)"></app-photo>
  <mat-card-content [formGroup]="$any(questionForm)" *subscribe="formDisabled$; let formDisabled">
    <div fxLayout="column" fxLayoutAlign="space-between">
      <section class="checkbox-group" *ngFor="let option of optionsControl.controls">
        <mat-checkbox class="checkbox" [checked]="true" [class.mat-checkbox-disabled]="false" disabled>
          <mat-form-field [formGroup]="$any(option)">
            <input matInput formControlName="label" />
            <button type="button" mat-icon-button (click)="removeOption(option.value)" *ngIf="!formDisabled">
              <mat-icon>clear</mat-icon>
            </button>
            <mat-error>{{ 'errors.required-answer' | transloco }}</mat-error>
          </mat-form-field>
        </mat-checkbox>
      </section>
      <mat-error *ngIf="optionsControl.hasErrorAndTouched('required') || optionsControl.hasErrorAndTouched('minlength')">{{
        'errors.required-answers' | transloco
      }}</mat-error>
      <section fxLayout="row" fxLayoutAlign="space-between center">
        <mat-checkbox [formControl]="allowOtherControl">{{ 'additional-answer' | transloco }}</mat-checkbox>
        <button type="button" mat-button (click)="addOption()">Dodaj odpowiedź</button>
      </section>
    </div>
  </mat-card-content>
</mat-card>
