import {Component, Input} from '@angular/core';
import {FormArray, FormControl} from '@ngneat/reactive-forms';

@Component({
  selector: 'app-error-required',
  template: `<mat-error *ngIf="answerControl.hasErrorAndTouched('required')">{{ 'errors.required-answer' | transloco }}</mat-error>`,
  styles: []
})
export class ErrorRequiredComponent {
  @Input() answerControl!: FormControl<any> | FormArray<any>;
}
