<mat-card>
  <mat-card-title>
    {{ data?.question || 'Failed to load..' }}
  </mat-card-title>
  <app-photo [isEditMode]="false" [imageUrl]="getImageUrl"></app-photo>
  <mat-card-content *ngIf="data" [formGroup]="$any(answerForm)">
    <div fxLayout="column" fxLayoutAlign="space-between">
      <mat-radio-group fxFlex fxLayout="column" class="form-radio-group" [formControl]="answerControl">
        <mat-radio-button class="form-radio-button" *ngFor="let o of data.options" [value]="o.id">
          {{ o.label }}
        </mat-radio-button>
        <mat-radio-button class="form-radio-button" *ngIf="data.allowOther" [value]="otherId">
          <mat-form-field>
            <input matInput [formControl]="otherControl" placeholder="{{ 'specifyOther' | transloco }}" />
          </mat-form-field>
          <mat-error *ngIf="otherControl.hasErrorAndTouched('required')">{{ 'errors.required-answer' | transloco }}</mat-error>
        </mat-radio-button>
      </mat-radio-group>
      <app-error-required [answerControl]="answerControl"></app-error-required>
    </div>
  </mat-card-content>
</mat-card>
