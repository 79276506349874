<mat-card>
  <mat-card-title>
    {{ data?.question || 'Failed to load..' }}
  </mat-card-title>
  <app-photo [isEditMode]="false" [imageUrl]="getImageUrl"></app-photo>
  <mat-card-content *ngIf="data">
    <div fxLayout="column" fxLayoutAlign="space-between">
      <div fxFlex fxLayout="column" class="answers-container">
        <mat-checkbox
          class="form-checkbox"
          *ngFor="let o of data.options; index as i"
          [value]="o.id.toString()"
          (change)="onCheckChange($event)"
        >
          {{ o.label }}
        </mat-checkbox>
        <mat-checkbox class="form-checkbox" *ngIf="data.allowOther" [value]="otherId.toString()" (change)="onCheckChange($event)"
          ><mat-form-field
            ><input matInput [formControl]="otherControl" placeholder="{{ 'specifyOther' | transloco }}" />

            <mat-error *ngIf="otherControl.hasErrorAndTouched('required')">{{ 'errors.required-answer' | transloco }}</mat-error>
          </mat-form-field>
        </mat-checkbox>
      </div>
      <mat-error *ngIf="answerControl.hasErrorAndTouched('required') || answerControl.hasErrorAndTouched('minlength')">{{
        'errors.required-answer' | transloco
      }}</mat-error>
    </div>
  </mat-card-content>
</mat-card>
