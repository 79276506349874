import {Directive, EventEmitter, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[appNgInit]'
})
export class NgInitDirective implements OnInit {
  @Output()
  appNgInit: EventEmitter<void> = new EventEmitter();

  ngOnInit() {
    this.appNgInit.emit();
  }
}
