import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {FormControl, FormGroup} from '@ngneat/reactive-forms';
import {FormViewBaseComponent} from '@shared/components/base/form-view-base';

import {FormScaleAnswer, FormScaleQuestion} from '../form-scale.model';

@Component({
  selector: 'app-form-scale-view',
  templateUrl: './form-scale-view.component.html',
  styleUrls: ['./form-scale-view.component.scss']
})
export class FormScaleViewComponent extends FormViewBaseComponent<FormScaleQuestion, FormScaleAnswer> implements OnInit {
  numbersArray: number[] = [];
  constructor() {
    super();
  }

  answerForm = new FormGroup<FormScaleAnswer>({
    answer: new FormControl<number>(undefined, Validators.required),
    questionId: new FormControl<number>(undefined, Validators.required)
  });

  ngOnInit(): void {
    if (this.data) {
      const minValue = this.data.min;
      this.numbersArray = Array.from({length: this.data.max - this.data.min + 1}, (_, i) => i + minValue);
    }
  }
}
