import {Component} from '@angular/core';
import {Validators} from '@angular/forms';
import {FormControl, FormGroup} from '@ngneat/reactive-forms';
import {FormViewBaseComponent} from '@shared/components/base/form-view-base';

import {FormOpenAnswer, FormOpenQuestion} from '../form-open.model';

@Component({
  selector: 'app-form-open-view',
  templateUrl: './form-open-view.component.html',
  styleUrls: ['./form-open-view.component.scss']
})
export class FormOpenViewComponent extends FormViewBaseComponent<FormOpenQuestion, FormOpenAnswer> {
  constructor() {
    super();
  }

  answerForm = new FormGroup<FormOpenAnswer>({
    answer: new FormControl('', Validators.required),
    questionId: new FormControl<number>(undefined, Validators.required)
  });
}
