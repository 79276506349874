<mat-card class="wrapper">
  <mat-card-content>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" fxLayout="column">
      <h2>{{ 'signIn' | transloco }}</h2>
      <mat-form-field class="full-width-input">
        <input matInput placeholder="{{ 'email' | transloco }}" formControlName="email" />
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput type="password" placeholder="{{ 'password' | transloco }}" formControlName="password" />
      </mat-form-field>
      <mat-error *ngIf="errorMessage"> {{ errorMessage }} </mat-error>
      <button mat-raised-button color="primary">{{ 'signIn' | transloco }}</button>
    </form>
  </mat-card-content>
</mat-card>
