<mat-card *transloco="let t">
  <mat-card-title>
    <mat-form-field class="question">
      <mat-label>{{ 'question' | transloco }}</mat-label>
      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="4" [formControl]="questionControl"> </textarea>
    </mat-form-field>
  </mat-card-title>
  <app-photo [isEditMode]="true" [imageUrl]="getImageUrl" (imageSelected)="handleImageUploaded($event)"></app-photo>
  <mat-card-content *subscribe="formDisabled$; let formDisabled">
    <div
      class="map-wrapper"
      leaflet
      [leafletLayersControl]="layersControl"
      [leafletOptions]="defaultMapOptions"
      (leafletCenterChange)="!formDisabled && onCenterChange($event)"
      (leafletZoomChange)="!formDisabled && onZoomChange($event)"
      (leafletMapReady)="onMapReady($event)"
    >
      <button
        type="button"
        class="leaflet-control leaflet-control-layers refresh-button"
        mat-icon-button
        (click)="resetMap(); $event.stopPropagation()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <mat-accordion>
      <mat-expansion-panel
        *ngFor="let tag of tagsControl.controls"
        [formGroup]="$any(tag)"
        class="tag-item"
        [ngStyle]="{overflow: colorPickerOpen ? 'visible' : 'hidden'}"
      >
        <mat-expansion-panel-header>
          <mat-panel-title
            appStopClickPropagation
            fxFlex="grow"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            (keydown.Space)="$event.stopImmediatePropagation()"
          >
            <div
              [innerHtml]="icon(tag) | safeHtml"
              class="tag-icon"
              cpPosition="top-right"
              [colorPicker]="tag.value.color"
              (colorPickerChange)="onColorSelect(tag, $event)"
              (cpToggleChange)="colorPickerOpen = $event"
              [cpDisabled]="formDisabled"
            ></div>
            <mat-form-field appearance="standard" fxFlex="1 0 100px">
              <mat-label>{{ t('tag.type') }}</mat-label>
              <mat-select formControlName="type">
                <mat-option *ngFor="let type of tagTypes" [value]="type.id">
                  {{ type.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard" fxFlex="2 0 100px">
              <mat-label>{{ t('tag.label') }}</mat-label>
              <input matInput formControlName="label" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="min-max">
              <mat-label>Min</mat-label>
              <input matInput type="number" formControlName="minCount" [min]="0" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="min-max">
              <mat-label>Max</mat-label>
              <input matInput type="number" formControlName="maxCount" [min]="tag.value.minCount || 1" />
            </mat-form-field>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <section
          *ngIf="tag.value.type == tagType.Circle"
          fxLayout="row wrap"
          fxLayoutAlign="space-around center"
          fxLayoutGap="1rem"
          class="radius-section"
          @slide
        >
          <mat-label>{{ t('tag.radius') }}:</mat-label>
          <mat-form-field appearance="standard" class="min-max-slider">
            <mat-label>Min</mat-label>
            <input matInput type="number" formControlName="minRadius" [min]="10" [max]="maxInput.value" #minInput />
            <span matSuffix>m</span>
            <mat-slider
              matSuffix
              [min]="10"
              [max]="maxSlider.value"
              [value]="minInput.value"
              (valueChange)="minInput.value = $any($event)"
              step="10"
              thumbLabel
              [disabled]="formDisabled"
              #minSlider
            ></mat-slider>
          </mat-form-field>
          <mat-form-field appearance="standard" class="min-max-slider">
            <mat-label>Max</mat-label>
            <input matInput type="number" formControlName="maxRadius" [min]="minInput.value" [max]="10000" #maxInput />
            <span matSuffix>m</span>
            <mat-slider
              matSuffix
              [min]="minSlider.value"
              [max]="10000"
              [value]="maxInput.value"
              (valueChange)="maxInput.value = $any($event)"
              step="10"
              thumbLabel
              [disabled]="formDisabled"
              #maxSlider
            ></mat-slider>
          </mat-form-field>
        </section>

        <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
          <section *ngFor="let control of ['select', 'comment', 'datePicker']" fxFlex="0 1 auto" fxLayout="column">
            <mat-label>{{ t('tag.' + control) }}</mat-label>
            <mat-button-toggle-group [formControlName]="control">
              <mat-button-toggle [value]="controlAvailability.Off">{{ t('tag.off') }}</mat-button-toggle>
              <mat-button-toggle [value]="controlAvailability.On">{{ t('tag.on') }}</mat-button-toggle>
              <mat-button-toggle [value]="controlAvailability.Required">{{ t('tag.required') }}</mat-button-toggle>
            </mat-button-toggle-group>
          </section>
        </div>

        <div class="tag-select-options" *ngIf="!tagSelectOptions(tag).disabled" @slide>
          <mat-form-field appearance="standard" class="tag-select-chips">
            <mat-label>{{ t('tag.select') }}</mat-label>
            <mat-chip-list #chipList [disabled]="tagSelectOptions(tag).disabled" (appNgInit)="onChipListInit(tag, chipList)">
              <mat-chip *ngFor="let option of tagSelectOptions(tag).controls" (removed)="removeTagSelectOption(tag, option.value)">
                {{ option.value.label }}
                <mat-icon matChipRemove *ngIf="!formDisabled">cancel</mat-icon>
              </mat-chip>
              <input
                placeholder="{{ t('edit.add') }}"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addTagSelectOption(tag, $event)"
                [disabled]="formDisabled"
              />
            </mat-chip-list>
            <mat-error *ngIf="tagSelectOptions(tag).hasError('required') || tagSelectOptions(tag).hasError('minlength')">
              {{ t('errors.required-options') }}
            </mat-error>
          </mat-form-field>
          <mat-slide-toggle formControlName="selectMultiple"> {{ t('tag.selectMultiple') }} </mat-slide-toggle>
        </div>
        <mat-action-row *ngIf="!formDisabled">
          <button mat-button (click)="removeTag(tag.value)">{{ t('remove') }}</button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-error *ngIf="tagsControl.hasError('required') || tagsControl.hasError('minlength')">{{ t('errors.required-tags') }}</mat-error>

    <button mat-icon-button class="button-add" *ngIf="!formDisabled" (click)="addTag()">
      <mat-icon>add</mat-icon>
    </button>
  </mat-card-content>
</mat-card>
