<mat-card *transloco="let t">
  <mat-card-title>
    {{ data?.question || 'Failed to load..' }}
  </mat-card-title>
  <app-photo [isEditMode]="false" [imageUrl]="getImageUrl"></app-photo>
  <mat-card-content *ngIf="data">
    <div
      class="map-wrapper"
      leaflet
      [leafletLayersControl]="layersControl"
      [leafletOptions]="data.mapOptions"
      [leafletLayers]="(layers$ | async)!"
      (leafletClick)="onMapClick($event)"
      (leafletMapReady)="onMapReady($event)"
    ></div>
    <mat-list>
      <mat-list-item *ngFor="let marker of answerControl.controls" class="marker-list-item">
        <div [formGroup]="$any(marker)" fxFlex="grow" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em">
          <div fxFlex="1 1 auto" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1em">
            <div fxLayout="row" fxLayoutAlign="start center" class="marker-icon-label">
              <div [innerHtml]="tag(marker).icon.createIcon().innerHTML | safeHtml"></div>
              <h4>{{ tag(marker).label }}</h4>
            </div>
            <mat-form-field fxFlex="nogrow" *ngIf="tag(marker).datePicker" class="marker-date" appearance="standard">
              <mat-label>{{ t('tag.selectDate') }}</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                formControlName="date"
                [max]="maxDate"
                matTooltip="{{ t('errors.futureDate') }}"
                [matTooltipDisabled]="!marker.hasError('matDatepickerMax', 'date')"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker type="datetime" #picker [twelveHour]="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex="1 1 auto" *ngIf="tag(marker).select" appearance="standard">
              <mat-label>{{ t('tag.select') }}</mat-label>
              <mat-select formControlName="selection" [multiple]="tag(marker).selectMultiple">
                <mat-option *ngFor="let option of tag(marker).selectOptions" [value]="option.id">{{ option.label }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="1 1 auto" *ngIf="tag(marker).comment" class="marker-comment" appearance="standard">
              <input
                matInput
                #commentInput
                formControlName="comment"
                placeholder="{{ t('comment') }}"
                (ngModelChange)="onCommentChange()"
              />
            </mat-form-field>
          </div>
          <button
            *ngIf="tag(marker).type == tagType.Circle"
            type="button"
            mat-icon-button
            matTooltip="{{ t('changeRadius') }}"
            #radiusButton
            (click)="showRadiusControl($event, radiusButton, marker)"
          >
            <mat-icon>height</mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="{{ t('pointToMarker') }}" (click)="pointToMarker(marker.value)">
            <mat-icon>place</mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="{{ t('remove') }}" (click)="removeMarker(marker.value)">
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      </mat-list-item>
      <mat-hint *ngIf="!answerControl.controls.length">{{ t('mapHint') }}</mat-hint>
      <app-error-required [answerControl]="answerControl"></app-error-required>
      <mat-error *ngIf="answerControl.hasErrorAndTouched('tagsNotUsed')">{{
        t('errors.tagsNotUsed', {tags: answerControl.errors.tagsNotUsed.tags})
      }}</mat-error>
    </mat-list>
  </mat-card-content>
</mat-card>

<ng-template #markerSelector let-latLng>
  <mat-selection-list [multiple]="false" class="popover" (selectionChange)="onMarkerSelected($event, latLng)">
    <mat-list-option *ngFor="let tag of tagsAvailable$ | async" [value]="tag" [disabled]="tag.maxCount ? tag.used >= tag.maxCount : false">
      <div matListIcon class="marker-icon" [innerHtml]="tag.icon.createIcon().innerHTML | safeHtml"></div>
      <h4 matLine>{{ tag.label }}</h4>
      <h4 matLine>{{ tag.usedText }}</h4>
    </mat-list-option>
  </mat-selection-list>
</ng-template>

<ng-template #radiusSlider let-config>
  <div class="popover">
    <mat-slider
      [min]="config.tag.minRadius"
      [max]="config.tag.maxRadius"
      step="10"
      thumbLabel
      [displayWith]="formatSliderLabel"
      [formControl]="config.control"
      (valueChange)="onMarkerRadiusChange()"
    ></mat-slider>
  </div>
</ng-template>
