import {Component} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@ngneat/reactive-forms';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(private auth: AngularFireAuth, private router: Router, private transloco: TranslocoService) {}

  loginForm = new FormGroup<LoginCredentials>({
    email: new FormControl<string>(undefined, [Validators.required, Validators.email]),
    password: new FormControl<string>(undefined, Validators.required)
  });

  errorMessage: string | null = null;

  onSubmit() {
    if (this.loginForm.valid) {
      const {email, password} = this.loginForm.value;
      this.auth
        .signInWithEmailAndPassword(email, password)
        .then(() => this.router.navigate(['/manage']))
        .catch(error => {
          /**
           * t(auth/user-not-found, auth/wrong-password, auth/user-disabled, auth/invalid-email)
           */
          this.errorMessage = this.transloco.translate(error.code) ?? error.message;
        });
    }
  }
}

interface LoginCredentials {
  email: string;
  password: string;
}
