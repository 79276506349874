import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {FormViewBaseComponent} from '@shared/components/base/form-view-base';
import {map} from 'rxjs/operators';

import {FormMultiAnswer, FormMultiQuestion} from '../form-multi.model';

@Component({
  selector: 'app-form-multi-view',
  templateUrl: './form-multi-view.component.html',
  styleUrls: ['./form-multi-view.component.scss']
})
export class FormMultiViewComponent extends FormViewBaseComponent<FormMultiQuestion, FormMultiAnswer> implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.otherControl.disabledWhile(this.answerControl.value$.pipe(map(v => !v.includes(this.otherId))));
  }
  otherId = -1;

  answerForm = new FormGroup<FormMultiAnswer>({
    answer: new FormArray<number>([], [Validators.required, Validators.minLength(1)]),
    other: new FormControl<string>(undefined, Validators.required),
    questionId: new FormControl<number>(undefined, Validators.required)
  });

  get otherControl() {
    return this.answerForm.getControl('other') as FormControl<string>;
  }

  get answersControl() {
    return this.answerForm.getControl('answer') as FormArray<number>;
  }

  onCheckChange(event: any) {
    const answerArray = this.answersControl;
    if (event.source) {
      if (event.checked) {
        if (+event.source.value) {
          answerArray.push(new FormControl(+event.source.value, Validators.required));
        }
      } else {
        answerArray.controls.forEach((answer, index) => {
          if (answer.value == +event.source.value) {
            answerArray.removeAt(index);
            return;
          }
        });
      }
    }
  }
}
