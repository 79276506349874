import {Component, QueryList, ViewChildren} from '@angular/core';
import {Validators} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {FormSingleQuestion} from '@shared/components';
import {FormEditBaseComponent} from '@shared/components/base/form-edit-base';
import {SelectOption} from '@shared/models/form.model';
import {FormQuestionType} from '@shared/models/form-question-type.enum';

@Component({
  selector: 'app-form-single-edit',
  templateUrl: './form-single-edit.component.html',
  styleUrls: ['./form-single-edit.component.scss']
})
export class FormSingleEditComponent extends FormEditBaseComponent<FormSingleQuestion> {
  @ViewChildren(MatInput) optionInputs!: QueryList<MatInput>;

  questionForm = new FormGroup<FormSingleQuestion>({
    id: new FormControl<number>(),
    type: new FormControl(FormQuestionType.Single),
    question: new FormControl('', Validators.required),
    imageUrl: new FormControl(),
    imageFile: new FormControl(),
    options: new FormArray<SelectOption>([], [Validators.required, Validators.minLength(2)]),
    allowOther: new FormControl(false)
  });

  get optionsControl() {
    return this.questionForm.getControl('options') as FormArray<SelectOption>;
  }
  get allowOtherControl() {
    return this.questionForm.getControl('allowOther');
  }
  set value(value: FormSingleQuestion) {
    if (value.options) {
      const {options, imageFile, ...other} = value;
      this.questionForm.patchValue(other);
      this.questionForm.setControl(
        'options',
        this.fb.array(
          options.map(({id, label}) => this.fb.group<SelectOption>({id, label: [label, Validators.required]})),
          [Validators.required, Validators.minLength(2)]
        )
      );
    } else {
      this.questionForm.setValue(value);
    }
  }

  constructor(private fb: FormBuilder) {
    super();
  }

  addOption() {
    const id = Math.max(...this.optionsControl.value.map(opt => opt.id), 0) + 1;
    this.optionsControl.push(
      new FormGroup<SelectOption>({
        id: new FormControl(id),
        label: new FormControl<string>(undefined, Validators.required)
      })
    );
    setTimeout(() => {
      this.optionInputs.get(this.optionInputs.length - 1)?.focus();
    }, 50);
  }

  removeOption(option: SelectOption) {
    this.optionsControl.remove(option);
  }
}
