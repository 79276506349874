<mat-card>
  <mat-card-title>
    {{ data?.question || 'Failed to load..' }}
  </mat-card-title>
  <app-photo [isEditMode]="false" [imageUrl]="getImageUrl"></app-photo>
  <mat-card-content *ngIf="data">
    <div fxLayout="column" fxLayoutAlign="space-between">
      <mat-radio-group
        fxFlex
        fxLayout="row"
        fxFlexAlign="space-around"
        class="form-radio-group scale-view-radio-group"
        [formControl]="answerControl"
      >
        <div *ngIf="data.minLabel" class="label-container" fxFlexAlign="center">{{ data.minLabel }}</div>
        <div fxFlex fxLayout="column" fxLayoutAlign="space-around center" *ngFor="let o of numbersArray">
          <mat-radio-button fxLayout="column" fxLayoutAlign="center" class="scale-radio-button" [value]="o">
            {{ o }}
          </mat-radio-button>
        </div>
        <div *ngIf="data.maxLabel" class="label-container" fxFlexAlign="center">{{ data.maxLabel }}</div>
      </mat-radio-group>
      <app-error-required [answerControl]="answerControl"></app-error-required>
    </div>
  </mat-card-content>
</mat-card>
