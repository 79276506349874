import {FormAnswerBase, FormQuestionBase, SelectOption} from '@shared/models/form.model';
import {JSONAnswer} from '@shared/models/to-json';

export interface FormMultiQuestion extends FormQuestionBase {
  options: SelectOption[];
  allowOther: boolean;
}

export interface FormMultiAnswer extends FormAnswerBase<number[]> {
  other?: string;
}

export function multiToJSON({question, options}: FormMultiQuestion, {answer, other}: FormMultiAnswer): JSONAnswer<string[]> {
  const labels = options.reduce((dict: Record<number, string>, opt) => ({...dict, [opt.id]: opt.label}), {});
  return {
    question,
    answer: [...answer.map(id => labels[id]), ...(other ? [other] : [])]
  };
}
