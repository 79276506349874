import {NgModule} from '@angular/core';
import {canActivate, isNotAnonymous} from '@angular/fire/auth-guard';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from '@core/home/home.component';
import {LoginComponent} from '@core/login/login.component';
import {RegisterComponent} from '@core/register/register.component';
import {ClosedComponent} from '@shared/components/closed/closed.component';
import {FilledComponent} from '@shared/components/filled/filled.component';
import {ThankYouComponent} from '@shared/components/thank-you/thank-you.component';
import {pipe} from 'rxjs';
import {map} from 'rxjs/operators';

const redirectUnauthorizedToLogin = () =>
  pipe(
    isNotAnonymous,
    map(loggedIn => loggedIn || ['login'])
  );

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {path: 'form', loadChildren: () => import('./modules/form/form.module').then(m => m.FormModule)},
  {
    path: 'manage',
    loadChildren: () => import('./modules/manage/manage.module').then(m => m.ManageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'thank-you',
    component: ThankYouComponent
  },
  {
    path: 'closed',
    component: ClosedComponent
  },
  {
    path: 'filled',
    component: FilledComponent
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
