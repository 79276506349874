import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SharedModule} from '@shared/shared.module';

import {ConfigModule} from './config/config.service';
import {HomeComponent} from './home/home.component';
import {LocalizationInterceptor} from './i18n/localization.interceptor';
import {TranslocoRootModule} from './i18n/transloco-root.module';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';

@NgModule({
  declarations: [HomeComponent, LoginComponent, RegisterComponent],
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    TranslocoRootModule,
    AngularFireModule,
    AngularFirestoreModule.enablePersistence(),
    // modules for error component
    MatSnackBarModule,
    MatIconModule
  ],
  providers: [ConfigModule.init(), {provide: HTTP_INTERCEPTORS, useClass: LocalizationInterceptor, multi: true}]
})
export class CoreModule {}
