import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {TranslocoService} from '@ngneat/transloco';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss']
})
export class MenuItemsComponent implements OnInit {
  @Input() screenWidth!: number;
  @Input() clickLogoEvent!: Observable<null>;
  constructor(private auth: AngularFireAuth, private router: Router, public transloco: TranslocoService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.screenWidth) {
      this.screenWidth = changes.screenWidth.currentValue;
    }
  }

  ngOnInit() {
    this.clickLogoEvent.subscribe(() => (this.isMenuOpen = false));
  }

  isMenuOpen = false;
  loggedIn$ = this.auth.user.pipe(map(x => x && !x.isAnonymous));

  signIn() {
    this.router.navigate(['/login']);
  }

  routeToManage() {
    this.router.navigate(['/manage']);
  }

  routeToUserPanel() {
    this.router.navigate(['/manage/user-panel']);
  }

  register() {
    this.router.navigate(['/register']);
  }

  signOut() {
    this.auth.signOut().then(() => {
      this.router.navigate(['/home']);
    });
  }
}
