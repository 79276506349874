import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {ChangeDetectionStrategy, Component, HostListener, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {Validators} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatSelectionListChange} from '@angular/material/list';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {TranslocoService} from '@ngneat/transloco';
import {FormViewBaseComponent} from '@shared/components/base/form-view-base';
import {Circle, circle, DivIcon, LatLngLiteral, LeafletEvent, LeafletMouseEvent, Map, Marker, marker, Polygon, polygon} from 'leaflet';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, map, shareReplay, withLatestFrom} from 'rxjs/operators';

import {MapTagAnswer} from '..';
import {
  ControlAvailability,
  FormMapAnswer,
  FormMapQuestion,
  MapAnswer,
  MapCircleTag,
  MapPlotAnswer,
  MapTag,
  MapTagType,
  tagIcon
} from '../form-map.model';
import {MapLayersService} from '../map-layers.service';

type IconTag = MapTag & {icon: DivIcon};

@Component({
  selector: 'app-form-map-view',
  templateUrl: './form-map-view.component.html',
  styleUrls: ['./form-map-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MapLayersService]
})
export class FormMapViewComponent extends FormViewBaseComponent<FormMapQuestion, FormMapAnswer> implements OnInit {
  @ViewChild('markerSelector') markerSelectorRef!: TemplateRef<unknown>;
  @ViewChild('radiusSlider') radiusSliderRef!: TemplateRef<unknown>;

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    const clickTarget = event.target as HTMLElement;
    if (this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget)) {
      this.closeOverlay();
    }
  }

  private map: Map | undefined;
  private overlayRef: OverlayRef | null = null;
  layersControl = this.mapLayers.layersControl;

  constructor(
    private mapLayers: MapLayersService,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private snackBar: MatSnackBar,
    private transloco: TranslocoService,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.data) {
      this.tags = this.data.tags.reduce((obj, t) => ({...obj, [t.id]: {...t, icon: tagIcon(t.color, t.type)}}), {});
    }
    this.answerControl.setAsyncValidators(this.markersValidator);
  }

  answerForm = new FormGroup<FormMapAnswer>({
    answer: new FormArray<MapAnswer>([], Validators.required),
    questionId: new FormControl<number>(undefined, Validators.required)
  });

  get answerControl() {
    return this.answerForm.getControl('answer') as FormArray<MapAnswer>;
  }

  tagType = MapTagType;
  tags: Record<MapTag['id'], IconTag> = {};
  tag = (marker: AbstractControl<MapAnswer>) => this.tags[marker.value.tagId];
  tagLayers = {
    [MapTagType.Marker]: (m: MapAnswer, tag: IconTag) => marker(m.latLng, {icon: tag.icon, draggable: true}),
    [MapTagType.Circle]: (m: MapAnswer, tag: IconTag) => circle(m.latLng, {color: tag.color, radius: m.radius}),
    [MapTagType.Plot]: (m: MapAnswer, tag: IconTag) => polygon((m as MapPlotAnswer).plotPolygon, {color: tag.color})
  };
  maxDate = new Date(Date.now());

  markersUpdate$ = new BehaviorSubject<void>(undefined);
  commentUpdate$ = new BehaviorSubject<void>(undefined);

  private markers$ = combineLatest([this.markersUpdate$, this.commentUpdate$.pipe(debounceTime(1000))]).pipe(
    withLatestFrom(this.answerControl.value$),
    map(([, markers]) => markers),
    shareReplay(1)
  );

  layers$: Observable<(Marker | Circle | Polygon)[]> = this.markers$.pipe(
    map(markers =>
      markers.map((m, i) => {
        const tag = this.tags[m.tagId];
        const layer = this.tagLayers[tag.type](m, tag);

        if (tag.type === MapTagType.Plot) {
          let tooltip = (m as MapPlotAnswer).plotId;
          if (m.comment) tooltip += ': ' + m.comment;
          layer.bindTooltip(tooltip);
        } else if (m.comment) {
          layer.bindTooltip(m.comment);
        }
        if (tag.type === MapTagType.Marker) {
          layer.on('dragend', event => this.onMarkerDrag(event, i));
        }
        return layer;
      })
    )
  );

  tagsAvailable$ = this.markersUpdate$.pipe(
    withLatestFrom(this.markers$),
    map(([, markers]) => {
      const tagsUsed = markers.reduce((acc: Record<number, number>, m) => ({...acc, [m.tagId]: (acc[m.tagId] || 0) + 1}), {});
      return Object.values(this.tags).map(t => ({
        ...t,
        used: tagsUsed[t.id] || 0,
        usedText: `(${tagsUsed[t.id] || 0}/${t.maxCount ? t.maxCount : '∞'}${t.minCount ? ', min: ' + t.minCount : ''})`
      }));
    }),
    shareReplay(1)
  );

  onMapReady(map: Map) {
    this.map = map;
    this.layersControl.baseLayers['Open Street Map'].addTo(map);
    if (this.data?.tags.some(t => t.type === MapTagType.Plot)) {
      this.layersControl.overlays.GUGiK.addTo(map);
    }
  }
  onMapClick(event: LeafletMouseEvent) {
    this.closeOverlay();
    const {x, y} = event.originalEvent;
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({x, y})
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(
      new TemplatePortal(this.markerSelectorRef, this.viewContainerRef, {
        $implicit: {...event.latlng}
      })
    );
    event.originalEvent.stopPropagation();
  }
  onMarkerSelected({options}: MatSelectionListChange, latlng: LatLngLiteral) {
    const selected = options.filter(x => x.selected)[0];
    this.closeOverlay();
    this.addMarker(selected.value, latlng);
  }
  addMarker(tag: MapTag, latlng: LatLngLiteral) {
    const marker = this.fb.group<MapTagAnswer>({
      latLng: latlng,
      tagId: tag.id,
      ...(tag.comment && {comment: tag.comment == ControlAvailability.Required ? [undefined, Validators.required] : undefined}),
      ...(tag.datePicker && {date: tag.datePicker == ControlAvailability.Required ? [undefined, Validators.required] : undefined}),
      ...(tag.select && {selection: tag.select == ControlAvailability.Required ? [undefined, Validators.required] : undefined}),
      ...(tag.type == MapTagType.Circle && {radius: this.defaultRadius(tag as MapCircleTag)})
    });

    if (tag.type === MapTagType.Plot) {
      this.mapLayers
        .getPlot(latlng)
        .pipe(withLatestFrom(this.answerControl.value$))
        .subscribe(([plot, value]) => {
          if (plot) {
            if (value.some(x => (x as MapPlotAnswer).plotId === plot.id)) {
              this.snackBar.open(this.transloco.translate('tag.plotAlreadyAdded'), undefined, {duration: 5000});
              return;
            }
            const plotMarker = marker as FormGroup<MapPlotAnswer>;
            plotMarker.addControl('plotId', this.fb.control(plot.id));
            plotMarker.addControl('plotPolygon', this.fb.control(plot.latlngs));
            this.answerControl.push(plotMarker);
            this.markersUpdate$.next();
          } else {
            this.snackBar.open(this.transloco.translate('tag.plotNotFound'), undefined, {duration: 5000});
          }
        });
    } else {
      this.answerControl.push(marker);
      this.markersUpdate$.next();
    }
  }
  defaultRadius = (tag: MapCircleTag) => Math.round((tag.minRadius + tag.maxRadius) / 2);

  onMarkerDrag(event: LeafletEvent, markerIndex: number) {
    const marker = event.target as Marker;
    this.answerControl.controls[markerIndex].patchValue({latLng: marker.getLatLng()});
  }

  showRadiusControl(event: MouseEvent, origin: MatButton, marker: AbstractControl<MapTagAnswer>) {
    this.closeOverlay();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin._getHostElement())
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(
      new TemplatePortal(this.radiusSliderRef, this.viewContainerRef, {
        $implicit: {
          tag: this.tag(marker),
          control: (marker as FormGroup<MapTagAnswer>).getControl('radius')
        }
      })
    );
    event.stopPropagation();
  }
  formatSliderLabel(value: number) {
    if (value < 1000) return value + 'm';
    else return Math.round(value / 1000) + 'km';
  }

  pointToMarker(marker: MapTagAnswer) {
    this.map?.panTo(marker.latLng);
  }
  removeMarker(marker: MapTagAnswer) {
    this.answerControl.remove(marker);
    this.markersUpdate$.next();
  }
  onMarkerRadiusChange() {
    this.markersUpdate$.next();
  }
  onCommentChange() {
    this.commentUpdate$.next();
  }

  private closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  private markersValidator = () =>
    this.tagsAvailable$.pipe(
      map(tags =>
        tags.reduce(
          (error: Record<string, {tags: string}> | null, tag) =>
            tag.minCount && tag.used < tag.minCount
              ? {
                  tagsNotUsed: {
                    tags: `${error?.tagsNotUsed ? error.tagsNotUsed.tags + ', ' : ''}${tag.label} (${tag.minCount - tag.used})`
                  }
                }
              : error,
          null
        )
      )
    );
}
